import request from '@/utils/request'
import { iResponse } from '@/services/types'

export default () =>
  new (class {
    requestchainslist(params: any): Promise<iResponse> {
      return request.get(`/requestchains/list`, { params })
    }

    requestchains(params: any): Promise<iResponse> {
      return request.get(`/requestchains`, { params })
    }

    graph(params: any): Promise<iResponse> {
      return request.get(`/engine/graph`, { params })
    }

    topology(params: any): Promise<iResponse> {
      return request.get(`/requestchains/topology`, { params })
    }

    fuzzsearch(params: any): Promise<iResponse> {
      return request.get(`/fuzzsearch/agent/project`, { params })
    }
    serverReplay(params: any): Promise<iResponse> {
      return request.post(`/server_replay`, params)
    }
    getserverReplay(params: any): Promise<iResponse> {
      return request.get(`/server_replay`, { params })
    }

    getProjectTopology(params: any): Promise<iResponse> {
      return request.get(`/project_topology`, {
        params,
        baseURL: '/api/v2'
      })
    }
    getProjectTopologyByHash(hash: any): Promise<iResponse> {
      return request.get(`/project_topology/${hash}`, {
        baseURL: '/api/v2'
      })
    }
    
    getEntryGraph(data: any): Promise<iResponse> {
      return request.post(`/project_topology/entry_graph`, data, {
        baseURL: '/api/v2'
      })
    }
  })()
